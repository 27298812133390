/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateOrderAPIDto,
  Order,
  OrdersControllerNotifyWaiterRequest,
  UpdateOrderDto,
} from '../models';
import {
    CreateOrderAPIDtoFromJSON,
    CreateOrderAPIDtoToJSON,
    OrderFromJSON,
    OrderToJSON,
    OrdersControllerNotifyWaiterRequestFromJSON,
    OrdersControllerNotifyWaiterRequestToJSON,
    UpdateOrderDtoFromJSON,
    UpdateOrderDtoToJSON,
} from '../models';

export interface OrdersControllerCreateOrderRequest {
    createOrderAPIDto: CreateOrderAPIDto;
}

export interface OrdersControllerDeleteOrderRequest {
    id: number;
}

export interface OrdersControllerGetActiveOrderByTableNumberRequest {
    id: number;
    tableNumber: number;
    code: string;
}

export interface OrdersControllerGetLatestOrdersRequest {
    localeId: number;
    since: string;
}

export interface OrdersControllerGetOrderByIdRequest {
    id: number;
}

export interface OrdersControllerMergeTablesRequest {
    fromId: number;
    toId: number;
    localeId: number;
}

export interface OrdersControllerMergeTablesApiKeyRequest {
    fromId: number;
    toId: number;
    localeId: number;
}

export interface OrdersControllerNotifyClientAcceptWaiterRequest {
    id: number;
}

export interface OrdersControllerNotifyClientOrderStatusRequest {
    id: number;
}

export interface OrdersControllerNotifyClientWaiterDeliveredTableRequest {
    id: number;
    code: string;
}

export interface OrdersControllerNotifyWaiterOperationRequest {
    id: number;
    ordersControllerNotifyWaiterRequest: OrdersControllerNotifyWaiterRequest;
}

export interface OrdersControllerNotifyWaiterReceiptCallRequest {
    id: number;
}

export interface OrdersControllerUpdateAllOrdersRequest {
    id: number;
    code: string;
    status: string;
}

export interface OrdersControllerUpdateOrderRequest {
    id: number;
    updateOrderDto: UpdateOrderDto;
}

/**
 * 
 */
export class OrdersApi extends runtime.BaseAPI {

    /**
     */
    async ordersControllerCreateOrderRaw(requestParameters: OrdersControllerCreateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.createOrderAPIDto === null || requestParameters.createOrderAPIDto === undefined) {
            throw new runtime.RequiredError('createOrderAPIDto','Required parameter requestParameters.createOrderAPIDto was null or undefined when calling ordersControllerCreateOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderAPIDtoToJSON(requestParameters.createOrderAPIDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async ordersControllerCreateOrder(requestParameters: OrdersControllerCreateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.ordersControllerCreateOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async ordersControllerDeleteOrderRaw(requestParameters: OrdersControllerDeleteOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ordersControllerDeleteOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersControllerDeleteOrder(requestParameters: OrdersControllerDeleteOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersControllerDeleteOrderRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersControllerGetActiveOrderByTableNumberRaw(requestParameters: OrdersControllerGetActiveOrderByTableNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Order>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ordersControllerGetActiveOrderByTableNumber.');
        }

        if (requestParameters.tableNumber === null || requestParameters.tableNumber === undefined) {
            throw new runtime.RequiredError('tableNumber','Required parameter requestParameters.tableNumber was null or undefined when calling ordersControllerGetActiveOrderByTableNumber.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling ordersControllerGetActiveOrderByTableNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/restaurants/{id}/tables/{tableNumber}/code/{code}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"tableNumber"}}`, encodeURIComponent(String(requestParameters.tableNumber))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderFromJSON));
    }

    /**
     */
    async ordersControllerGetActiveOrderByTableNumber(requestParameters: OrdersControllerGetActiveOrderByTableNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Order>> {
        const response = await this.ordersControllerGetActiveOrderByTableNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async ordersControllerGetLatestOrdersRaw(requestParameters: OrdersControllerGetLatestOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Order>>> {
        if (requestParameters.localeId === null || requestParameters.localeId === undefined) {
            throw new runtime.RequiredError('localeId','Required parameter requestParameters.localeId was null or undefined when calling ordersControllerGetLatestOrders.');
        }

        if (requestParameters.since === null || requestParameters.since === undefined) {
            throw new runtime.RequiredError('since','Required parameter requestParameters.since was null or undefined when calling ordersControllerGetLatestOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.localeId !== undefined) {
            queryParameters['localeId'] = requestParameters.localeId;
        }

        if (requestParameters.since !== undefined) {
            queryParameters['since'] = requestParameters.since;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/updates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderFromJSON));
    }

    /**
     */
    async ordersControllerGetLatestOrders(requestParameters: OrdersControllerGetLatestOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Order>> {
        const response = await this.ordersControllerGetLatestOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async ordersControllerGetOrderByIdRaw(requestParameters: OrdersControllerGetOrderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ordersControllerGetOrderById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     */
    async ordersControllerGetOrderById(requestParameters: OrdersControllerGetOrderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Order> {
        const response = await this.ordersControllerGetOrderByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async ordersControllerMergeTablesRaw(requestParameters: OrdersControllerMergeTablesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fromId === null || requestParameters.fromId === undefined) {
            throw new runtime.RequiredError('fromId','Required parameter requestParameters.fromId was null or undefined when calling ordersControllerMergeTables.');
        }

        if (requestParameters.toId === null || requestParameters.toId === undefined) {
            throw new runtime.RequiredError('toId','Required parameter requestParameters.toId was null or undefined when calling ordersControllerMergeTables.');
        }

        if (requestParameters.localeId === null || requestParameters.localeId === undefined) {
            throw new runtime.RequiredError('localeId','Required parameter requestParameters.localeId was null or undefined when calling ordersControllerMergeTables.');
        }

        const queryParameters: any = {};

        if (requestParameters.localeId !== undefined) {
            queryParameters['localeId'] = requestParameters.localeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/admin-table/{fromId}/merge/{toId}`.replace(`{${"fromId"}}`, encodeURIComponent(String(requestParameters.fromId))).replace(`{${"toId"}}`, encodeURIComponent(String(requestParameters.toId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersControllerMergeTables(requestParameters: OrdersControllerMergeTablesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersControllerMergeTablesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersControllerMergeTablesApiKeyRaw(requestParameters: OrdersControllerMergeTablesApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fromId === null || requestParameters.fromId === undefined) {
            throw new runtime.RequiredError('fromId','Required parameter requestParameters.fromId was null or undefined when calling ordersControllerMergeTablesApiKey.');
        }

        if (requestParameters.toId === null || requestParameters.toId === undefined) {
            throw new runtime.RequiredError('toId','Required parameter requestParameters.toId was null or undefined when calling ordersControllerMergeTablesApiKey.');
        }

        if (requestParameters.localeId === null || requestParameters.localeId === undefined) {
            throw new runtime.RequiredError('localeId','Required parameter requestParameters.localeId was null or undefined when calling ordersControllerMergeTablesApiKey.');
        }

        const queryParameters: any = {};

        if (requestParameters.localeId !== undefined) {
            queryParameters['localeId'] = requestParameters.localeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/table/{fromId}/merge/{toId}`.replace(`{${"fromId"}}`, encodeURIComponent(String(requestParameters.fromId))).replace(`{${"toId"}}`, encodeURIComponent(String(requestParameters.toId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersControllerMergeTablesApiKey(requestParameters: OrdersControllerMergeTablesApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersControllerMergeTablesApiKeyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersControllerNotifyClientAcceptWaiterRaw(requestParameters: OrdersControllerNotifyClientAcceptWaiterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ordersControllerNotifyClientAcceptWaiter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/table/{id}/notifyClientAcceptWaiterCall`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersControllerNotifyClientAcceptWaiter(requestParameters: OrdersControllerNotifyClientAcceptWaiterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersControllerNotifyClientAcceptWaiterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersControllerNotifyClientOrderStatusRaw(requestParameters: OrdersControllerNotifyClientOrderStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ordersControllerNotifyClientOrderStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/table/{id}/notifyClient`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersControllerNotifyClientOrderStatus(requestParameters: OrdersControllerNotifyClientOrderStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersControllerNotifyClientOrderStatusRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersControllerNotifyClientWaiterDeliveredTableRaw(requestParameters: OrdersControllerNotifyClientWaiterDeliveredTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ordersControllerNotifyClientWaiterDeliveredTable.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling ordersControllerNotifyClientWaiterDeliveredTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/table/{id}/code/{code}/notifyClientAcceptWaiterCall`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersControllerNotifyClientWaiterDeliveredTable(requestParameters: OrdersControllerNotifyClientWaiterDeliveredTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersControllerNotifyClientWaiterDeliveredTableRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersControllerNotifyWaiterRaw(requestParameters: OrdersControllerNotifyWaiterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ordersControllerNotifyWaiter.');
        }

        if (requestParameters.ordersControllerNotifyWaiterRequest === null || requestParameters.ordersControllerNotifyWaiterRequest === undefined) {
            throw new runtime.RequiredError('ordersControllerNotifyWaiterRequest','Required parameter requestParameters.ordersControllerNotifyWaiterRequest was null or undefined when calling ordersControllerNotifyWaiter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/orders/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrdersControllerNotifyWaiterRequestToJSON(requestParameters.ordersControllerNotifyWaiterRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersControllerNotifyWaiter(requestParameters: OrdersControllerNotifyWaiterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersControllerNotifyWaiterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersControllerNotifyWaiterReceiptCallRaw(requestParameters: OrdersControllerNotifyWaiterReceiptCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ordersControllerNotifyWaiterReceiptCall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/table/{id}/notifyWaiterReceiptCall`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersControllerNotifyWaiterReceiptCall(requestParameters: OrdersControllerNotifyWaiterReceiptCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersControllerNotifyWaiterReceiptCallRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersControllerUpdateAllOrdersRaw(requestParameters: OrdersControllerUpdateAllOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ordersControllerUpdateAllOrders.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling ordersControllerUpdateAllOrders.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling ordersControllerUpdateAllOrders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/table/{id}/code/{code}/updateAllOrders/{status}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))).replace(`{${"status"}}`, encodeURIComponent(String(requestParameters.status))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ordersControllerUpdateAllOrders(requestParameters: OrdersControllerUpdateAllOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ordersControllerUpdateAllOrdersRaw(requestParameters, initOverrides);
    }

    /**
     */
    async ordersControllerUpdateOrderRaw(requestParameters: OrdersControllerUpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ordersControllerUpdateOrder.');
        }

        if (requestParameters.updateOrderDto === null || requestParameters.updateOrderDto === undefined) {
            throw new runtime.RequiredError('updateOrderDto','Required parameter requestParameters.updateOrderDto was null or undefined when calling ordersControllerUpdateOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrderDtoToJSON(requestParameters.updateOrderDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async ordersControllerUpdateOrder(requestParameters: OrdersControllerUpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.ordersControllerUpdateOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
